import { render, staticRenderFns } from "./ConcentrationService.vue?vue&type=template&id=187d6a2e&scoped=true&"
import script from "./ConcentrationService.vue?vue&type=script&lang=js&"
export * from "./ConcentrationService.vue?vue&type=script&lang=js&"
import style0 from "./ConcentrationService.vue?vue&type=style&index=0&id=187d6a2e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187d6a2e",
  null
  
)

export default component.exports
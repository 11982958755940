<template>
    <div class="material">
        <transition  enter-active-class="animate__animated animate__fadeInBottom" appear>

            <div>
                <p>支吊架材料统计</p>
                <img src="@/../public/images/drawing15.png" alt="">
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name:'Material',
}
</script>

<style scoped>
    .material{
        padding: 10px;
        text-align: center;
        background-color: #fff;
    }
    .material p{
        font-size: 25px;
        color: rgb(43, 43, 43);
        font-weight: bold;
    }
    .material img{
        margin-bottom: 50px;
    }
    .material span{
        display: block;
        margin-bottom: 50px;
    }
</style>

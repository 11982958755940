<template>
    <div>
        <NavigationBar style="margin-bottom:20px"/>
        <keep-alive :include="['HomeHeader','NavigationBar']">

          <router-view></router-view>
          <router-view ></router-view>
          <!-- 轮播图 -->
<!--          <el-carousel :interval="3000" arrow="always" height="800px" >-->
<!--              <el-carousel-item v-for="(i,index) in img" :key="index" >-->

<!--              </el-carousel-item>-->
<!--          </el-carousel>-->

          <PlayerVideo />
        </keep-alive>
    </div>
</template>

<script>
import NavigationBar from '@/components/header/NavigationBar.vue';
import PlayerVideo from "@/components/home/PlayerVideo.vue";

export default {
    name: 'HeaderPage',
    components:{PlayerVideo, NavigationBar},
    data(){
        return{
            img:['@/assets/img/image1.jpg','@/assets/img/image2.jpg','@/assets/img/image3.jpg','@/assets/img/image4.jpg','@/assets/img/image5.jpg','@/assets/img/image6.jpg','@/assets/img/image7.jpg']
        }
    }
}
</script>

<style scoped>
    .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
  }

  .el-carousel__item:nth-child(7n+1) {
    background-color: #d3dce6;
    background: url(@/assets/img/image1.jpg) no-repeat 100% 80%;
    background-size: 100% 100%;
  }

  .el-carousel__item:nth-child(7n+2) {
    background: url(@/assets/img/image2.jpg) no-repeat 100% 80%;
    background-size: 100% 100%;
    background-color: #d3dce6;
  }
  .el-carousel__item:nth-child(7n+3) {
    background: url(@/assets/img/image3.jpg) no-repeat 100% 80%;
    background-size: 100% 100%;
    background-color: #d3dce6;
  }
  .el-carousel__item:nth-child(7n+4) {
    background: url(@/assets/img/image4.jpg) no-repeat 100% 80%;
    background-size: 100% 100%;
    background-color: #d3dce6;
  }
    .el-carousel__item:nth-child(7n+5) {
        background: url(@/assets/img/image5.jpg) no-repeat 100% 80%;
        background-size: 100% 100%;
        background-color: #d3dce6;
    }
    .el-carousel__item:nth-child(7n+6) {
        background: url(@/assets/img/image6.jpg) no-repeat 100% 80%;
        background-size: 100% 100%;
        background-color: #d3dce6;
    }
    .el-carousel__item:nth-child(7n+7) {
        background: url(@/assets/img/image7.jpg) no-repeat 100% 80%;
        background-size: 100% 100%;
        background-color: #d3dce6;
    }
  /deep/ .el-carousel__indicators{
    top: 550px;
  }
</style>

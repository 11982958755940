<template>
    <div class="project">
        <p>智能Email解决方案</p>
        <span>结合智能DNS保障全球Email服务质量</span>
        <br>
        <img src="@/../public/images/email.png" alt="">
    </div>
</template>

<script>

export default {
    name:'Email'

}
</script>

<style scoped>
    .project{
        text-align: center;
        margin-top: 100px;
    }
    .project p:nth-child(1){
        font-size: 30px;
        font-weight: bold;
        color: rgb(52, 52, 52);
    }
</style>
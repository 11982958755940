<template>
    <div class="websiteInfo">
        <el-row :gutter="20" type="flex" justify="space-between" style="margin:0px 18%;">
            <el-col :span="24" style="margin-top: 10px; margin-bottom: 20px; font-size: 12px;text-align: center;">
                <p>copyright © 2022 武汉蓝狐科技有限公司</p>
                <p>咨询热线：18571654332</p>
                <div style="width:400px;margin:0 auto;">
                    <a target="_blank" href="https://beian.miit.gov.cn/"
                       style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                        <img src="@/assets/img/jinhui.png" style="float:left;"/>
                        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">鄂公网安备
                            42018502006786号 | 鄂ICP备2023012750号-1</p>
                    </a>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>


export default {
    name: 'FooterBar',
}
</script>

<style scoped>
.websiteInfo {
    position: relative;
    font-size: 12px;
    bottom: 0px;
    width: 100%;
    clear: both;
    background-color: #292827;
    color: #ffffff;
}

/deep/ [data-v-4fd998ea] .el-header[data-v-4fd998ea], .el-footer[data-v-4fd998ea][data-v-4fd998ea] {
    background-color: rgb(245, 245, 246);
}

</style>

<template>
    <div>
        <transition
                appear
                name="animate__animated animate__bounce"
                enter-active-class="animate__fadeInLeft"
        >
            <div v-show="aboutUs_Flag" class="companyIntroduction">
                <span style="font-size: 30px; color: blue; ">企业介绍</span> <br>
                <div>
                    <p style="font-size: 27px;text-align:center;margin-top: 30px;color:blue">了解蓝狐</p>
                    <div class="introduce">
                        <p>
                            公司成立于2022年，主营业务专注电力、石油化工、船舶海事、医疗等行业三维设计解决方案，致力于工程设计三维数据化、信息化，为企业提供一站式
                            的综合解决方案，以“客户满意，精益求精，和谐共荣，不断创新”为经营理念，核心人员拥有石油化工领域20年生产建设经验，是目前行业领先的高科技平台类服务提供商。
                            <!-- </p> -->
                            <!-- <p> -->
                            代理英国剑维公司（AVEVA）、LFM公司软件产品的销售、售后服务和第二次开发，拥有丰富的实务经验。
                            我公司可根据用户需求，提供不同的工程三维设计软件、激光三维扫描技术、专家培训与强化指导。
                        </p>
                        <p>
                            公司将通过先进的“精益施工，精细设计”的设计和管理工具，以行业顾问的身份，成为客户的专业智囊，帮助客户实现降低成本、提高效率、提升协作和消除风险。
                            公司发力于为客户直接解决核心痛点，创造性的研发了自主核心知识产权的一系列软件产品：
                        </p>
                        <p>
                            一类——AVEVA软件平台精益施工，精细设计系列软件：AVEVA PDMS(E3D)出图软件； AVEVA PDMS(E3D)支吊架软件；
                            AVEVA PDMS(E3D)总图竖向设计软件； AVEVA PDMS(E3D)材料统计软件； AVEVA PDMS(E3D)ISO图后处理软件；
                        </p>
                        <p>
                            二类——设计软件集成平台系列软件： 基于全面AVEVA 设计软件的集成设计平台系统； 基于化二院软件基础平台在AVEVA
                            Engineering软件中实现设计软件数据集成的设计管理平台； 基于自主开发数据平台实现多专业数据、图表设计集成的EPCI数据管理平台
                        </p>

                        <p>
                            着眼未来，我公司还将发展国产三维设计软件，更好的服务于中国工厂工程市场。
                        </p>
                    </div>
                    <br>
                    <div class="honour" style="text-align: center;">
                        <p style="font-size: 27px;text-align:center;margin-top: 30px;color:blue">荣誉奖项</p>
                        <ul>
                            <!-- 如果想要添加图片记得要去 src/mixin.js 修改滚轮滚动距离显示的像素值-->
                            <!-- <li><img src="../../../assets/img/image1.jpg" alt=""></li> -->
                            <!-- <li><img src="../../../assets/img/background.png" alt=""></li> -->
                            <!-- <li><img src="../../../assets/img/background.png" alt=""></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {alignTransition} from '@/mixin'

export default {
    name: 'CompanyIntroduce',
    mixins: [alignTransition]
}
</script>

<style scoped>
.companyIntroduction {
    position: relative;
    left: 5%;
    width: 80%;
    height: 100%;
    overflow: hidden;
    padding: 30px;
    margin-top: 30px;
}

.companyIntroduction .honour li {
    float: left;
    margin-right: 50px;
    margin-left: 60px;
    margin-top: 50px;
}

.companyIntroduction .honour img {
    width: 450px;
    height: 400px;
}

li {
    list-style-type: none;
}

p {
    /* 216,216,216 */
    color: rgb(0, 0, 0);
    text-indent: 2em;
}

/deep/ .el-icon-s-opportunity {
    font-size: 30px;
    color: rgb(255, 255, 129);
}

.companyIntroduction .introduce p {
    /* position: relative;
    left: 4%;
    top: -50px; */
    margin-bottom: -10px;
    margin-left: 35px;
}
</style>

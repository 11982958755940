<template>
    <div>
        <Project/>
        <br><br><br><br>
        <!--        <Advantage/>    -->
        <example/>
        <br><br><br><br>
        <material/>
    </div>
</template>

<script>
// import Advantage from './Advantage/index.vue'
import Project from './Project/index.vue'
import Example from "@/pages/Solution/Trade/Content/Example/index.vue";
import Material from "@/pages/Solution/Trade/Content/Material/index.vue";

export default {
    name: 'TradeMain',
    components: {
        Material,
        Example,
        // Advantage,
        Project
    }
}
</script>

<template>
    <div class="trade">
        <transition  enter-active-class="animate__animated animate__fadeInUp" appear>

            <div>
                <p>支吊架选型</p>
                <span>一套程序支持多个标准，只需要切换数据库</span>
                <img src="@/../public/images/drawing10.png" alt="">
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name:'Project',
}
</script>

<style scoped>
    .trade{
        padding: 10px;
        text-align: center;
        background-color: #fff;
    }
    .trade p{
        font-size: 25px;
        color: rgb(43, 43, 43);
        font-weight: bold;
    }
    .trade img{
        margin-bottom: 50px;
    }
    .trade span{
        display: block;
        margin-bottom: 50px;
    }
</style>

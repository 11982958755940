<template>
    <div>
        <transition enter-active-class="animate__animated animate__backInDown" appear>
            <div class="extend">
                <p>平面出图示例-管道</p>
                <div class="image-container">
                        <img src="@/../public/images/drawing02.png" alt="">
                        <img src="@/../public/images/drawing03.png" class="image03" alt="">
                </div>


                <p>平面出图示例-管道</p>
                <img src="@/../public/images/drawing04.png" alt="">

                <p>平面出图示例-医药管道</p>
                <img src="@/../public/images/drawing05.png" alt="">

                <p>平面出图示例-桥架布置图</p>
                <img src="@/../public/images/drawing06.png" alt="">

                <p>平面出图示例-管口方位图</p>
                <img src="@/../public/images/drawing07.png" alt="">

                <p>平面出图示例-支吊架</p>
                <img src="@/../public/images/drawing09.png" alt="">
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'SystemExtend',
}
</script>

<style scoped>
    .extend{
        margin-left: 10%;
        width: 80%;
        padding-top: 30px;
        text-align: center;
    }
    .extend span{
        font-size: 25px;
        font-weight: bold;
    }
    p{
        text-align: left;
        text-indent: 2em;
        font-size: 25px;
        font-weight: bold;
        margin-top: 50px;
    }
    .image-container{
        display: flex;
        justify-content: space-between;
    }
    .image03{
        width: 700px;
        height: 509px;
    }
</style>

<template>
    <div>
        <transition enter-active-class="animate__animated animate__fadeInRight" appear>
            <div class="news" v-show="AboutFlag_2">
                <span style="font-size: 30px; color:blue ">新闻中心</span>
                <br><br>
                <div class="text">
                    <el-link :underline="false" style="font-size:20px;">
                        <span>蛰伏1500天，华为Mate 60 Pro「突袭」揭开大转折序幕？</span>
                        <i>2023-08-30</i>
                        <hr>
                        <p>
                            华为活了！！！毫无预兆开售的华为Mate 60系列的宣传片中，雅鲁藏布江大拐弯得到了一个特写，就像是一种隐喻：在蛰伏1500余天之后，华为手机业务终于迎来大转折。
                            8月29日中午，华为商城官方微信公众号宣布，推出 HUAWEI Mate 60 Pro先锋计划，突然开售Mate 60 Pro，“让部分消费者提前体验史上最强大的Mate手机”，在未开发布会的情况下，在华为商城及部分线下门店开启售卖。
                            除了商店里显示的外观、价格和相关配置信息以外，华为仅仅低调地通过一封公开信的方式对最新款手机进行了介绍。
                        </p>
                    </el-link>
                    <el-link :underline="false" style="font-size:20px;">
                        <span>华为不打算在海外市场推出Mate 60 Pro 手机，外媒表示遗憾</span>
                        <i>2023-05-20</i>
                        <hr>
                        <p>
                            华为向外媒 Android Authority 表示，不打算在中国以外的市场推出 Mate 60 Pro，也拒绝对此处理器是否支持 5G 发表评论。
                            Android Authority 感叹道：“这真是一个遗憾，因为 Mate 60 Pro 看起来确实是一款令人印象深刻的旗舰手机，尽管缺少谷歌服务集成。”
                        </p>
                    </el-link>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {AboutAnimation} from "@/mixin"


export default {
    name: 'NewsCenter',
    mixins:[AboutAnimation],

}
</script>

<style scoped>
    .news{
        position: relative;
        left: 5%;
        width: 80%;
        height: 100%;
        overflow: hidden;
        padding: 30px;
    }
    .text .el-link:hover{
        color: rgb(249, 11, 106);  /* 247,158,74  */
    }
    .text .el-link span{
        font-size:22px;
        font-weight:bolder;
    }
    .text .el-link i{
        float: right;
        font-style:normal;
        font-size:18px;
        font-weight:bolder;
        color: rgb(159, 164, 164);
    }
    /deep/.el-link.el-link--default{
        /* color: rgb(105, 140, 246); */
        color: rgb(98, 98, 98);
    }
    /deep/.text .el-link{
        margin-left: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .text p{
        color:rgb(107, 173, 180);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 15px;
        text-indent: 2em;
    }
</style>

<template>
    <div>
        <HomeHeader/>
        <FooterBar/>
    </div>
</template>

<script>
import HomeHeader from './home_page/HomeHeader.vue';
import FooterBar from "@/components/footer/FooterBar.vue";

export default {
    name: 'HomePage',
    components:{FooterBar, HomeHeader}
}
</script>

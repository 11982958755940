<template>
    <div class="trade">
        <transition  enter-active-class="animate__animated animate__fadeInLeft" appear>
            <div>
                <p>工厂工程三维数据化，信息化，EPCI一体化解决方案</p>
                <span>我公司可根据用户需求，提供不同的工程三维设计软件、激光三维扫描技术、专家培训与强化指导</span>
                <div class="image">
                    <img src="@/../public/images/drawing16.png" alt="">
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name:'Firm',
}
</script>

<style scoped>
    .trade{
        padding: 10px;
        text-align: center;
        margin-top: 30px;
    }
    .trade p{
        font-size: 35px;
        color: rgb(43, 43, 43);
        font-weight: bold;
    }
    .trade .image{
        padding-top: 50px;
        margin-bottom: 50px;
        background-color: #fff;
    }
    .trade span{
        display: block;
        margin-bottom: 20px;
    }
</style>

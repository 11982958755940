<template>
    <transition enter-active-class="animate__animated animate__fadeInLeft">
        <div class="example" v-show="TradeFlag_1">
            <p>示例</p>
            <div>
                <ul>

                    <li>
                        <img src="@/../public/images/drawing13.png" alt="">
                    </li>
                    <li>
                        <img src="@/../public/images/drawing14.png" alt="">
                    </li>
                    <li>
                        <img src="@/../public/images/drawing11.png" alt="">
                    </li>
                    <li>
                        <img src="@/../public/images/drawing12.png" alt="">
                    </li>
                </ul>

            </div>
        </div>
    </transition>

</template>

<script>
import {TradeAnimation} from '@/mixin'

export default {
    name: 'Example',
    mixins: [TradeAnimation]

}
</script>

<style scoped>
.example {
    padding: 10px;
    min-height: 300px;
    text-align: center;
    background-color: #fff;
}

.example p:nth-child(1) {
    /* margin-top: 100px; */
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: rgb(43, 43, 43);
}

.example ul {
    display: flex;
   overflow-y: auto;
}

.example ul li {
    width: auto;
    list-style-type: none;
    padding: 15px;
    margin-right: 45px;
    margin-bottom: 50px;
    margin-top: 30px;
    box-shadow: 0 0 14px 0 hsl(0deg 0% 83% / 50%);
    justify-content: space-around;
}

.example ul li span {
    font-size: 40px;
    color: rgb(102, 119, 249);
    padding: 10px;
}

.example ul li:hover {
    color: #626ca7;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgb(167, 209, 254);
}
</style>

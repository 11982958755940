<!-- controls：向用户显示播放按钮控件 -->

<template>
<div  class="center" style="text-align: center;">

    <h3>一套支吊架，支持多种支吊架标准</h3>

    <video  ref="video"

            class="video-js vjs-default-skin vjs-big-play-centered"

            width="800"

            height="600"

            controls>


    </video>
    <h3>蓝狐科技平面出图，出图准确率高，图面效果能达到99%以上，基本不用修改；出图效率高，复杂的管道需要1~2分钟</h3>
    <video  ref="drawing"

            class="video-js vjs-default-skin vjs-big-play-centered"

            width="800"

            height="600"

            controls>


    </video>
</div>

</template>

<script>
export default {

    data() {
        return {
            options: {
                autoplay: false,
                controls: true,
                sources: []
            }
        }
    },

    mounted() { // 渲染视频

        this.player = this.$video(this.$refs.video);
        this.player.src({
            src: require('../../../public/video/202405201917.mp4')
        })
        // 自动播放
        this.player.play();

        this.player = this.$video(this.$refs.drawing);
        this.player.src({
            src: require('../../../public/video/202405241025.mp4')
        })
        // 自动播放
        this.player.play()
    },
    beforeDestroy() {
        // 销毁video.js播放器
        if (this.player) {
            this.player.dispose()
        }
    }

};
</script>

<style>
.center {

    margin: 0 auto; /* 设置左右外边距为 auto，让它自动居中 */
    background-color: #f0f0f0; /* 为了可见性添加的背景颜色 */
    padding: 20px; /* 可选：添加内边距以增加内容与边框之间的距离 */
}
.video-js .vjs-big-play-button {

    font-size: 2.5em !important;

    line-height: 2.3em !important;

    height: 2.5em !important;

    width: 2.5em !important;

    -webkit-border-radius: 2.5em !important;

    -moz-border-radius: 2.5em !important;

    border-radius: 2.5em !important;

    background-color: #73859f;

    background-color: rgba(115, 133, 159, 0.5) !important;

    border-width: 0.15em !important;

    margin-top: -1.25em !important;

    margin-left: -1.75em !important;

}

.vjs-big-play-button .vjs-icon-placeholder {

    font-size: 1.63em !important;

}


.vjs-paused .vjs-big-play-button,

.vjs-paused.vjs-has-started .vjs-big-play-button {

    display: block !important;

}


.video-js .vjs-time-control {

    display: block !important;

}

.video-js .vjs-remaining-time {

    display: none !important;

}


</style>

<template>
<div class="advantage">
    <transition enter-active-class="animate__animated animate__fadeInRight" appear>
        <div v-show="CompanyFlag_1">
                <p>我们的优势</p>
                <div>
                    <ul>
                        <li>
                            <span class="el-icon-timer"></span>
                            <span>业务领域专注于石油化工、电力、船舶海事、医药造纸等领域。</span>
                        </li>
                        <li>
                            <span class="el-icon-help"></span>
                            <span>与英国剑维公司（AVEVA）、LFM公司软件产品的销售、鹰图（intergrafing），博超系列软件，AutoCAD，Benttly平台软件开发合作。</span>
                        </li>
                        <li>
                            <span class="el-icon-refresh"></span>
                            <span>与阿里云共同开发项目，技术实力过硬，服务态度严谨，已经成为阿里云供应商。</span>
                        </li>
                        <li>
                            <span class="el-icon-paperclip"></span>
                            <span>甲级中国电力设计院，中国的二十强船厂，化工八大设计院和其国外合作公司，炼化设计院，冶金设计院，众多的环保设计院都是我公司用户</span>
                        </li>
                    </ul>
                </div>
        </div>
    </transition>
</div>
</template>

<script>
import {CompanyAnimation} from '@/mixin'

export default {
    name:'Advantage',
    mixins:[CompanyAnimation]

}
</script>

<style scoped>
    .advantage{
        min-height: 300px;
        margin-bottom: 100px;
    }
    .advantage p:nth-child(1){
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: rgb(43, 43, 43);
    }
    .advantage ul{
        background-color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;

    }
    .advantage ul li{
        list-style-type: none;
        padding: 15px;
        margin-right: 45px;
        margin-bottom: 50px;
        margin-top: 30px;
        box-shadow:0 0 14px 0 hsl(0deg 0% 83% / 50%);
        border: 0.5px solid #ffe4e4;
        border-radius: 50px;
    }
    .advantage ul li span:nth-child(1){
        font-size: 40px;
        /* color: rgb(249, 102, 102); */
        color: #0127ff;
        padding: 10px;
    }
    .advantage ul li span:nth-child(2){
        position:relative;
        top: -7px;

    }
    .advantage ul li:hover{
        border-radius: 50px;
        color: #6bb2d5;
        box-shadow: 0 0 30px 0 #c3c8e0;
        background-color: rgb(251, 252, 246);
    }
    .advantage ul li span{
        position: relative;
        left: 25%;
    }
</style>

<template>
    <div>
        <transition enter-active-class="animate__animated animate__backInDown" appear>
            <div class="service">
<!--                <p>平面出图</p>-->
                <img src="@/../public/images/drawing01.png" alt="">

            </div>
        </transition>

    </div>
</template>

<script>

export default {
    name: 'ConcentrationService',
}
</script>

<style scoped>
    li{
        list-style-type: none;
    }
    img{
        width: 800px;
        height: 448px;
        margin-top: 45px;
    }
    .service{
        margin-left: 10%;
        width: 80%;
        text-align: center;
    }
    .service p:nth-child(1){
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        margin-top: 50px;
    }
    .service ul{
        display: flex;
        justify-content: center;
        margin-top: 100px;
    }
    .service ul li{
        margin-right: 80px;
    }
    .service ul li:nth-child(1){
        margin-left: 50px;
    }
    .service ul li p{
        font-size: 25px;
    }
</style>
